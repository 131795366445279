import {
  Period,
  SymbolInfo
} from "./models/api_models";
import { getKline } from './steam'
import dayjs from "dayjs";

export class CustomDatafeed {
  /**
   * 模糊搜索标的
   * 在搜索框输入的时候触发
   * 返回标的信息数组
   */
  async searchSymbols(search?: string): Promise<SymbolInfo[]> {
    // 根据模糊字段远程拉取标的数据
    console.log(11111)
  }

  /**
   * 获取历史k线数据
   * 当标的和周期发生变化的时候触发
   * 
   * 返回标的k线数据数组
   */
  async getHistoryKLineData(symbol: SymbolInfo, period: Period, from: number, to: number): Promise<KLineData[]> {
    // 完成数据请求
    let type = 1
    if (period.timespan == 'day') {
      type = 2
    }
    if (period.timespan == 'week') {
      type = 3
    }
    const now = new Date().getTime()
    if (now - to < 86400000) {
      to = null
    }

    return await ((await (await getKline(
      {
        type,
        maxTime: to ?  to / 1000 : ''
      }
    ))).data || []).map((s) => ({
      timestamp: s[0] * 1000,
      open: s[1],
      close: s[2],
      high: s[3],
      low: s[4]
    }));
  }

  /**
   * 订阅标的在某个周期的实时数据
   * 当标的和周期发生变化的时候触发
   * 
   * 通过callback告知图表接收数据
   */
  async subscribe(symbol: SymbolInfo, period: Period, callback: DatafeedSubscribeCallback): void {
    // 完成ws订阅或者http轮询
    console.log(33333)
  }

  /**
   * 取消订阅标的在某个周期的实时数据
   * 当标的和周期发生变化的时候触发
   * 
   */
  async unsubscribe(symbol: SymbolInfo, period: Period): void {
    // 完成ws订阅取消或者http轮询取消
    console.log(44444)
  }
}